<template>
  <div>
    <label class="label">{{ label }}</label>
    <v-text-field
      solo
      :disabled="disabled"
      v-model="data"
      :variant="variant"
      :type="type"
      :rules="rules"
      :readonly="readonly"
      :placeholder="placeholder"
      :prepend-inner-icon="prependInnerIcon"
      :append-icon="appendIcon"
      :outlined="outlined"
      :dense="dense"
      :required="required"
      @input="$emit('input', data)"
      @click:append="$emit('click:append')"
    ></v-text-field>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    prependInnerIcon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'outlined'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    data: ''
  }),
  watch: {
    value: function (value) {
      this.data = value;
    }
  },
  created() {
    this.data = this.value;
  }
};
</script>
<style lang="scss">
.label {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}
.v-input {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.0024em;
}
.v-text-field.v-text-field--solo {
  border-radius: 16px;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 46px;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none;
}
</style>
